<template>
  <div>
    <sg-top-overlay v-if="!hideOverlay && !embed" style="min-height: 79px">
      <div class="w-100">
        <sg-top-bar v-if="!hideOverlayNav"/>
      </div>
    </sg-top-overlay>
    <sg-top-overlay :back-url="`/farm/${selectedFarm.farm_id}${backUrl}`" v-if="backUrl"/>

    <div class="overlay-view mb-4"
         :class="{
            'hide-overlay': hideOverlay
         }" v-if="!loading">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SgTopBar from "@/components/Sg/SgTopBar";
import SgTopOverlay from "@/components/Sg/SgTopOverlay";

export default {
  name: "FarmOverlay",
  components: {
    SgTopOverlay,
    SgTopBar,
  },
  computed: {
    ...mapGetters('farm', [
      'selectedFarm',
      'canViewWaterQuality',
      'canEditWaterQuality',
      'canViewAnco',
      'canAddSampling',
      'canAddDisease',
      'canAddDeath',
      'canAddStock',
      'canAddHarvest',
      'canAddCycle',
      'canViewWarehouse',
      'canEditWarehouse',
      'canEditPond',
    ]),

    canViewUser() {
      return this.$store.getters["farm/canViewUser"]
    },
    canViewWarehouse() {
      return this.$store.getters["farm/canViewWarehouse"]
    },

    activeTab() {
      const route = this.$route.matched

      const routeLength = route.length

      if (route && route[routeLength - 1] && route[routeLength - 1].path && route[routeLength - 1].path.split('/')[3]) {
        return '/' + route[routeLength - 1].path.split('/')[3]
      }

      return '/farm'
    },
    loading() {
      return this.$store.state.farm.loading
    },
    hideOverlay() {
      return !this.$route.meta.overlay
    },
    hideOverlayNav() {
      return this.$route.meta.overlay_nav === false
    },
    embed() {
      return this.$route.query.output === 'embed'
    },

    backUrl() {
      return this.$route.meta.backUrl
    },
  },
  data: () => {
    return {
      reportModal: false,
    }
  },
  created() {
    this.getRole()
    this.getFarm()
  },
  methods: {
    ...mapActions('farm', [
      'getFarm',
      'getRole',
    ]),
  }
}
</script>

<style scoped lang="scss">
.sg-tab {
  .tag {
    border-radius: 20px;
    font-weight: normal;

    &.is-light {
      color: #838285;
    }
  }
}

.card {
  &.farm-edit {
    width: 450px;
    max-width: 100%;
  }
}
</style>
