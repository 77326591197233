<template>
  <div class="sg-top-bar">
    <div class="is-flex is-flex-direction-column top-bar-farm">
      <b-dropdown position="is-bottom-right" append-to-body aria-role="menu" trap-focus class="custom-dropdown" ref="farmDropdown" style="min-height: 30px">
        <div class="is-flex is-align-items-center" slot="trigger" slot-scope="{ active }" role="button">
          <span class="is-size-5 has-text-weight-bold farm-title is-flex is-align-items-center">
            {{ selectedFarm.name }}
            <b-tag class="ml-2" rounded :type="selectedFarm.status ? 'is-primary is-light' : ''">{{ selectedFarm.status ? 'Pro' : 'Lite' }}</b-tag>
          </span>
          <b-icon class="ml-2" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
        </div>
        <div style="min-width: 240px">
          <div class="sg-scroller">
            <b-dropdown-item aria-role="listitem" custom
                             class="is-flex is-justify-content-space-between is-align-items-center py-0 pl-0"
                             v-for="item in farm" :key="item.id">
              <div class="is-flex-grow-1 cursor-pointer pl-4 py-2">
                <p class="excerpt"
                   @click="selectFarm(item)">{{ item.name }}</p>
              </div>
              <div class="is-flex">
                <b-button type="is-text"
                          slot="trigger"
                          @click="editFarm(item)"
                          icon-left="pencil-outline"
                          size="is-small"
                          :title="$t('edit')"
                          v-if="item.canEditFarm">
                </b-button>
                <b-button type="is-text"
                          slot="trigger"
                          icon-left="trash-can-outline"
                          class="has-text-danger"
                          @click="deleteFarm(item.farm_id)"
                          size="is-small"
                          :title="$t('delete')"
                          v-if="item.user_id === user.user_id">
                </b-button>
                <b-button size="is-small" type="is-text" slot="trigger" @click="leaveFarm(item.farm_id)" :title="$t('leave')" v-else>
                  <b-icon icon="logout-variant" size="is-small" type="is-danger"></b-icon>
                </b-button>
              </div>
            </b-dropdown-item>
          </div>
          <b-dropdown-item
              class="custom-dropdown-item"
              aria-role="menu-item"
              :focusable="false"
              custom>
            <b-button type="is-danger"
                      icon-left="plus"
                      class="is-gradient btn-action mt-1 has-text-weight-light"
                      tag="router-link"
                      to="/farm-registration"
                      expanded>{{ $t('add') }} Farm
            </b-button>
          </b-dropdown-item>
        </div>
      </b-dropdown>
      <span class="has-text-weight-bold has-text-grey">{{ selectedFarm.city }}</span>
    </div>
    <div class="overlay-nav">
      <template v-if="isPro">
        <router-link data-menu-step="3" :to="`/farm/${selectedFarm.farm_id}/overview`" class="overlay-item" :title="$t('cycle.start') + ' & ' + $t('cycle.end') + ' ' + $t('cycle.cycle')">
          <img src="/img/assets/cycle.png" alt="Start & End Cycle" class="overlay-item-img">
          <span>{{ $t('cycle.set') }} {{ $t('cycle.cycle') }}</span>
        </router-link>
        <router-link :to="`/farm/${selectedFarm.farm_id}/comparison`" class="overlay-item" :title="$t('comparison.comparison')">
          <img src="/img/assets/comparison.png" alt="Comparison" class="overlay-item-img">
          <span>{{ $t('comparison.comparison') }}</span>
        </router-link>
      </template>
      <template v-else>
        <div data-menu-step="3" class="overlay-item" :title="$t('stock.stock')" @click="stockModal = true">
          <img src="/img/assets/stock-harvest.png" :alt="$t('stock.stock')" class="overlay-item-img">
          <span>{{ $t('stock.stock') }}</span>
        </div>
        <div class="overlay-item" :title="$t('cycle.end')" @click="cycleModal = true">
          <img src="/img/assets/cycle.png" :alt="$t('cycle.end')" class="overlay-item-img">
          <span>{{ $t('cycle.end') }}</span>
        </div>
      </template>
    </div>


    <!--  Modal Farm Form -->

    <b-modal
        v-model="farmModal"
        has-modal-card
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <farm-sign-up v-model="farmEdit" is-modal @close="props.close"></farm-sign-up>
      </template>
    </b-modal>

    <b-modal
        v-model="stockModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-stock-harvest is-modal @close="props.close" type="stock" @submit="handleAddStock" multiple/>
      </template>
    </b-modal>

    <b-modal
        v-model="cycleModal"
        has-modal-card
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal>
      <template #default="props">
        <input-start-end-cycle is-modal @close="props.close" filter-type="end" :end-status="2"/>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import InputStartEndCycle from "@/components/Farm/Sidebar/InputStartEndCycle";

export default {
  name: "SgTopBar",
  data: () => {
    return {
      farmModal: false,
      farmEdit: {},
      dropdown: false,
      stockModal: false,
      cycleModal: false,
    }
  },
  components: {
    InputStartEndCycle,
    InputStockHarvest: () => import("@/components/Farm/Sidebar/InputStockHarvest"),
    FarmSignUp: () => import("@/components/Farm/Registration/FarmSignUp")
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),
    ...mapGetters('farm', [
      'getRoleById',
      'selectedFarm',
      'isPro',
    ]),
    ...mapState('farm', [
      'farm',
    ]),
  },
  mounted() {
    // this.$store.commit('UPDATE_NAVBAR')
  },
  methods: {
    selectFarm(item) {
      this.$refs.farmDropdown.isActive = false
      this.$store.dispatch('farm/selectFarm', item)
    },
    editFarm(item) {
      this.$refs.farmDropdown.isActive = false
      this.farmModal = true
      this.farmEdit = item
    },
    deleteFarm(id) {
      this.$refs.farmDropdown.isActive = false
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/deleteFarm', id)
        this.$loading(false)
      })
    },
    leaveFarm(id) {
      this.$refs.farmDropdown.isActive = false
      this.$delete(async () => {
        this.$loading()
        await this.$store.dispatch('farm/leaveFarm', id)
        this.$loading(false)
      }, false, {
        message: 'Are you sure you want to leave this farm?',
      })
    },
    async handleAddStock() {
      await this.$store.dispatch('farm/getFarmInfo')
      // if (type === 'stock' && isAdd && this.hasStock === 1) {
      //   this.$store.commit('farm/UPDATE_TOURS', {
      //     tour_name: 'showBackToMenuTours',
      //     value: true,
      //   })
      // }
    },
  }
}
</script>
